import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import LayoutInfo from '../components/layoutInfo'

const InfoPage = ({
  data: {
    mdx: { slug, frontmatter, body },
  },
}) => (
  <LayoutInfo item={frontmatter} slug={slug}>
    <MDXRenderer>{body}</MDXRenderer>
  </LayoutInfo>
)

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      slug
      frontmatter {
        title
        description
      }
      body
    }
  }
`

export default InfoPage
